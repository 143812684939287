var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-form-group",
    { attrs: { invalid: _vm.form.$errors.any() } },
    [
      _c(
        "gov-label",
        { staticClass: "govuk-!-font-weight-bold", attrs: { for: _vm.id } },
        [
          _vm._t("label", function() {
            return [_vm._v(_vm._s(_vm.label))]
          })
        ],
        2
      ),
      _vm._t("hint", function() {
        return [
          _vm.hint
            ? _c("gov-hint", {
                attrs: { for: _vm.id },
                domProps: { textContent: _vm._s(_vm.hint) }
              })
            : _vm._e()
        ]
      }),
      _c("gov-file-upload", {
        ref: "file",
        attrs: {
          value: _vm.form.file,
          id: _vm.id,
          name: _vm.id,
          accept: _vm.accept
        },
        on: { change: _vm.onChange }
      }),
      _vm._t("after-input"),
      _vm.form.$submitting
        ? _c("ck-loader", [_vm._v("Uploading")])
        : _vm.existingUrl && !_vm.form.file && !_vm.removeExisting
        ? _c("img", {
            key: "ExistingImage::" + _vm._uid,
            attrs: { src: _vm.existingUrl, alt: "Existing image" }
          })
        : _vm.form.file
        ? _c("img", {
            key: "UploadedImage::" + _vm._uid,
            attrs: { src: _vm.form.file, alt: "Uploaded image" }
          })
        : _vm._e(),
      _vm.form.$errors.any()
        ? _c("gov-error-message", {
            attrs: { for: _vm.id },
            domProps: {
              textContent: _vm._s(
                _vm.form.$errors.get(["is_private", "mime_type", "file"])
              )
            }
          })
        : _vm._e(),
      _vm._t("after-error-message"),
      (_vm.existingUrl && !_vm.removeExisting) || _vm.form.file
        ? _c(
            "div",
            { staticClass: "govuk-!-margin-top-2" },
            [
              _c(
                "gov-button",
                {
                  attrs: { type: "button", error: "" },
                  on: { click: _vm.onRemove }
                },
                [_vm._v("Remove file")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }